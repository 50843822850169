import React from "react"

//components
import SEO from "../components/seo"
import Header2 from "../components/header2"
// import Footer from "../components/footer"

// Styles
import styles from "../styles/pages/discount-claimed.module.scss"

const DiscountClaimed = props => {
  return (
    <>
      <Header2 />
      <SEO title="Success" />
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.border}>
            <h1>
              You are Entitled to a<br />
              <span> 5000/= Discount </span> <br />
              on all Annual Memberships
            </h1>
            <h4>* Please Visit Us Within Two Weeks To Claim The Discount</h4>

            {props?.location?.state && (
              <div>
                <br />
                <h3>Name : {props?.location?.state?.name}</h3>
                <h3>Contact Number : {props?.location?.state?.tel}</h3>
                <h3>E-mail : {props?.location?.state?.email}</h3>
              </div>
            )}
            <br />
            <h5>
              * Take A Screenshot Of This And Come To The Gym To Get The
              Discount
            </h5>
          </div>
          {/* <Link to="/">
            <button className={styles.button}>Go Back to Home</button>
          </Link> */}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default DiscountClaimed
